import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Grid } from '@material-ui/core'

function processTableData(tableData, parseKey, parseMode){
    var responseData = JSON.parse(JSON.stringify(tableData))
    var rowAr = []
    if (parseMode === "tower") {
        for (var i in responseData[parseKey]["towerNumber"]) {
            rowAr.push({
                "id": i,
                "phExtRefId": responseData[parseKey]['phExtRefId'][i],
                "customerName": responseData[parseKey]['customerName'][i],
                "radCenter": responseData[parseKey]['radCenter'][i],
                "contractNumber": responseData[parseKey]['contractNumber'][i],
                "bvType": responseData[parseKey]['bvType'][i],
                "bvManufacturer": responseData[parseKey]['bvManufacturer'][i],
                "bvModel": responseData[parseKey]['bvModel'][i]
            })
        }
    }
    else if (parseMode === "ground"){
        for (var i in responseData[parseKey]["towerNumber"]) {
            rowAr.push({
                "id": i,
                "phExtRefId": responseData[parseKey]['phExtRefId'][i],
                "customerName": responseData[parseKey]['customerName'][i],
                "contractNumber": responseData[parseKey]['contractNumber'][i],
                "groundSpaceType": responseData[parseKey]['groundSpaceType'][i]
            })
        }
    }
    else {
        var customerSet = {};
        for (var i in responseData[parseKey]["towerNumber"]) {
            let customerName = responseData[parseKey]['customerName'][i]
            let contractNumber = responseData[parseKey]['contractNumber'][i]
            let radCenter = responseData[parseKey]['radCenter'][i]
            if (!(customerName in customerSet)){
                customerSet[customerName] = {
                    "id": i,
                    "customerName": customerName,
                    "contractNumber": contractNumber,
                    "radCenter": [radCenter]
                }
            }
            else{
                customerSet[customerName]["radCenter"].push(radCenter)
            }
        }
        for (var customerName in customerSet){
            rowAr.push({
                "id": customerSet[customerName]["id"],
                "customerName": customerSet[customerName]["customerName"],
                "contractNumber": customerSet[customerName]["contractNumber"],
                "radCenter": (customerSet[customerName]["radCenter"].reduce((a, b) => a + b) / customerSet[customerName]["radCenter"].length).toFixed(1)
            })
        }

    }

    return rowAr;
}

function DisplayDataGrid(props){
    //we only display if the mode is not tower
    if(props.tableMode === "tower"){
        return null
    }
    else{
        let tableStructure
        if (props.tableMode === 'contract'){
            tableStructure = props.tableStructures[props.tableMode]
        }
        else{
            tableStructure = props.tableStructures[props.equipmentTableMode]
        }
        let sortedRows
        if ((props.tableMode === "equipment" & props.equipmentTableMode === "tower") | (props.tableMode === "contract")){
            sortedRows = props.rows.sort((a, b) => b.radCenter - a.radCenter)
        }
        else{
            sortedRows = props.rows.sort((a, b) => {
                const nameA = a.customerName.toUpperCase();
                const nameB = b.customerName.toUpperCase();
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });
        }

        return(
            <Grid item>
                <DataGrid
                    autoHeight
                    rows={sortedRows}
                    columns={tableStructure}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    selectionModel={props.selectedRows}
                    onSelectionModelChange={(selectedRows) => {props.handleSelectedRows(selectedRows)}}
                >
                </DataGrid>
            </Grid>
        )
    }
}
function DataTable(props){
    if(props.isError){
        return null
    }
    else{
        const tableStructures = {
            "tower":[
                {   field: 'customerName', headerName: 'Customer Name', description: 'Customer Name', minWidth:180, flex:1 },
                {   field: 'contractNumber', headerName: 'Contract Number', description: 'Contract Number', minWidth:190, flex:1 },
                {   field: 'radCenter', headerName: 'RAD Center', description: 'RAD Center', minWidth:150, flex:1 },
                {   field: 'bvType', headerName: 'Equipment Type', description: 'Equipment Type', minWidth:178, flex:1 },
                {   field: 'bvManufacturer', headerName: 'Manufacturer', description: 'Manufacturer', minWidth:162, flex:1 },
                {   field: 'bvModel', headerName: 'Model', description: 'Model', minWidth:116, flex:1 },
                //{   field: 'phExtRefId', headerName: 'External Reference Id', description: 'External Reference Id', minWidth:211, flex:1 }
            ],
            "contract":[
                {   field: 'customerName', headerName: 'Customer Name', description: 'Customer Name', minWidth:180, flex:1 },
                {   field: 'contractNumber', headerName: 'Contract Number', description: 'Contract Number', minWidth:190, flex:1 },
                {   field: 'radCenter', headerName: 'Average RAD Center', description: 'Average RAD Center', minWidth:205, flex:1 }
            ],
            "ground": [
                {   field: 'customerName', headerName: 'Customer Name', description: 'Customer Name', minWidth:180, flex:1 },
                {   field: 'contractNumber', headerName: 'Contract Number', description: 'Contract Number', minWidth:190, flex:1 },
                {   field: 'groundSpaceType', headerName: 'Equipment Type', description: 'Ground Space Type', minWidth:178, flex:1 }
            ]
        }
    
        var rows
        if (props.tableData !== null & props.tableMode === "contract"){
            rows = processTableData(props.tableData, "equipmentDetails", "contract");
        }
        else if (props.tableData !== null & props.tableMode === "equipment" & props.equipmentTableMode === "tower"){
            rows = processTableData(props.tableData, "equipmentDetails", "tower");
        }
        else if (props.groundTableData !== null & props.tableMode === "equipment" & props.equipmentTableMode === "ground"){
            rows = processTableData(props.groundTableData, "groundDetails", "ground");
        }
        else {
            rows = [];
        }
    
        return (
            <Grid item container>
                <Grid item xs={false} sm={1} lg={1}></Grid>
                <Grid item xs={12} sm={10} lg={10}>
                    <DisplayDataGrid
                        rows={rows}
                        tableStructures={tableStructures}
                        tableMode={props.tableMode}
                        equipmentTableMode={props.equipmentTableMode}
                        selectedRows={props.selectedRows}
                        handleSelectedRows={(value) => props.handleSelectedRows(value)}
                    ></DisplayDataGrid>
                </Grid>
                <Grid item xs={false} sm={1} lg={1}></Grid>
            </Grid>
        );
    }
};

export default DataTable;