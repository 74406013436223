import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withRouter } from "react-router-dom"
import lostInSpace from "./assets/lostInSpace.png"

function Error(props){
    let message = "Whoops! Looks like something went wrong. Is your Zscaler enabled?"
    if (props.message !== undefined){
        message = props.message
    }
    return (
        <Grid item container direction="column" alignItems="center" spacing={1}>
            <Grid item>
                <img src={lostInSpace} alt="lostInSpace"></img>
            </Grid>
            <Grid item>
                <Typography variant="subtitle2">
                    {message}
                </Typography>
            </Grid>
    </Grid>
    );
};

export default withRouter(Error);
