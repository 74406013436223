function MakeApiCall(params){
    var url;
    var myHeaders = new Headers();
    var buildEnv = process.env.REACT_APP_BUILD_ENV;
    
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", params.hash);
    if (buildEnv === undefined | buildEnv === "master"){
      url = "https://qc.api.atcimagecreate.com/latest/" + params["endpoint"];
    }
    else if (buildEnv === "uat"){
      url = "https://uat.api.atcimagecreate.com/latest/" + params["endpoint"];
    }
    else if (buildEnv === "prod"){
      url = "https://prod.api.atcimagecreate.com/latest/" + params["endpoint"];
    }

    var formData; 
    if (params["payload"]){
      formData = JSON.stringify(params["payload"]);
    }
  
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
      };
    
    return fetch(url, requestOptions);
}

export default MakeApiCall;