import React from 'react';
import { Grid } from '@material-ui/core';

function Header(){
    return (
        <Grid item container>
            <Grid item xs={false} sm={1} lg={1}></Grid>
            <h1>Drone Images</h1>
        </Grid>
    );
};

export default Header;
