import React from 'react'
import { Grid, Typography } from '@material-ui/core'

function DataTableError(props){
    return(
        <Grid item container direction="column" alignItems="center">
            <Typography 
                style={{
                    color:"#D11242", 
                    fontWeight: 600
            }}>
                {props.message}
            </Typography>
        </Grid>
    )
}

function DataTableErrorHeader(props){
    if (props.tableMode === 'contract' & props.noTowerData){
        return (
            <DataTableError
                message={"No tower equipment found! Switch to tower images to browse a more general photo set."}
            >
            </DataTableError>
        )
    }
    else if (
        props.tableMode !== 'tower' & 
        props.equipmentTableMode === 'tower' & 
        props.noTowerData
    ){
        return (
            <DataTableError
                message={"No tower equipment found! Switch to tower images to browse a more general photo set."}
            >
            </DataTableError>
        )
    }
    else if (
        props.tableMode !== 'tower' & 
        props.equipmentTableMode === 'ground' & 
        props.noGroundData
    ){
        return (
            <DataTableError
                message={"No ground equipment found! Switch to tower images to browse a more general photo set."}
            >
            </DataTableError>
        )
    }
    else{
        return null
    }
}

export default DataTableErrorHeader