import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Grid } from '@mui/material';

function AnnotationModeSelection(props) {
    return (
        <Grid item>
            <FormControl component="fieldset">
                <FormLabel component="legend">View Selection</FormLabel>
                <RadioGroup 
                    row 
                    aria-label="annotationMode" 
                    name="modeSelectionGroup" 
                    value={props.annotationModeValue} 
                    onChange={(event)=>{props.handleAnnotationModeSelection(event.target.value);}}
                    >
                    <FormControlLabel value="towerSelect" control={<Radio color="primary"/>} label="Tower Images" />
                    <FormControlLabel value="carrierSelect" control={<Radio color="primary"/>} label="Carrier Images" />
                    <FormControlLabel value="equipmentSelect" control={<Radio color="primary"/>} label="Equipment Images" />
                </RadioGroup>
            </FormControl>
        </Grid>
    );
}

export default AnnotationModeSelection;