import React from "react";
import { Stack } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Divider, Grid, Typography } from "@material-ui/core";

import Header from "./header";
import DataTable from "./dataTable";
import TowerSelect from "./towerSelect";
import MakeApiCall from "./makeApiCall";
import ImageViewer from "./imageViewer";
import SubmitButton from "./submitButton";
import ParameterSelection from "./parameterSelection";
import AnnotationModeSelection from "./annotationModeSelection";

import { withRouter } from "react-router-dom";

import styles from 'react-responsive-carousel/lib/styles/carousel.min.css'
import SharePointRedirect from "./sharePointRedirect";
import DataTableRadioToggle from "./dataTableRadioToggle";
import DataTableErrorHeader from "./dataTableErrorHeader";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.makeImageCreateApiCall = this.makeImageCreateApiCall.bind(this);
        this.buildCriteriaString = this.buildCriteriaString.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.makeWarmUpCalls = this.makeWarmUpCalls.bind(this);
        this.handleTowerNumberChange = this.handleTowerNumberChange.bind(this);
        this.state = {
            hash: "",
            //Tower selection
            towerOptionAr: [],
            towerNumber: null,
            attemptedTowerNumber: null,
            flightDate:"",
            selectedRows: [],
            //Parameters
            disableParamMenu: false,
            annotationModeValue: "towerSelect",
            tableMode:"tower",
            equipmentTableMode: "tower",
            angleCount: 3,
            selectionModeValues:{
                imageSelectModeValue: {value:"minCover", label: "Minimum Cover"}, 
                maskModeValue: {value:"mergeMasks", label: "Merge Masks"},
            },
            parameterValues: {blnApplyMasks: false, blnBlur: false, blnAllMasks: true, blnCaption: true},
            //Results
            tableData: null,
            groundTableData: null,
            noTowerData: false,
            noGroundData: false,
            outputData: null,
            validAudit: true,
            isError: false,
            carouselId: 0,
            loading:false
        };
      }

      handleTableModeSelection(value){
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.equipmentTableMode = value;
        newState.selectedRows = []
        newState.outputData = null
        this.setState(newState);
      }
      handleAnnotationModeSelection(value){
        let newState = JSON.parse(JSON.stringify(this.state));
        //Reset parameters to the original state
        newState.selectedRows = []
        newState.outputData = null
        newState.annotationModeValue = value;
        newState.disableParamMenu=false;
        newState.parameterValues={blnApplyMasks: false, blnBlur: false, blnAllMasks: true, blnCaption: true};

        //Apply the appropriate modifications based on the selected mode
        if (newState.annotationModeValue  === "towerSelect"){
            newState.tableMode = "tower"
            newState.selectionModeValues={
                imageSelectModeValue: {value:"minCover", label: "Minimum Cover"}, 
                maskModeValue: {value:"mergeMasks", label: "Merge Masks"},

            }
        }
        else if (newState.annotationModeValue  === "carrierSelect"){
            newState.tableMode = "contract"
            newState.selectionModeValues={
                imageSelectModeValue: {value:"minCover", label: "Minimum Cover"}, 
                maskModeValue: {value:"mergeMasks", label: "Merge Masks"},

            }
            newState.parameterValues.blnApplyMasks = true
        }
        else if (newState.annotationModeValue  === "equipmentSelect"){
            newState.tableMode = "equipment"
            newState.equipmentTableMode = "tower"
            newState.selectionModeValues={
                imageSelectModeValue: {value:"optCover", label: "Optimal Cover"},
                maskModeValue: {value:"mergeMasks", label: "Merge Masks"}
            }
            newState.parameterValues.blnApplyMasks = true
        }
        this.setState(newState);
    }

    handleParameterChange(changeDict){
        let newState = JSON.parse(JSON.stringify(this.state));
        newState[changeDict.valueDict][changeDict.id] = changeDict.value;
        this.setState(newState);
    }

    handleTableUpdate(value){
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.updateTable = value;
        this.setState(newState);
    }

    handleSelectedRows(selectedRows){
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.selectedRows = selectedRows;
        this.setState(newState);
    }

    buildCriteriaString(){
        let tableMode = this.state.tableMode
        let equipmentTableMode = this.state.equipmentTableMode
        //let criteriaAr = [{"field": "edgeOfImage", "operator": "==", "value": 0}]
        let criteriaAr = []
        let criteriaValue = "["

        for(var i of this.state.selectedRows){
            if (tableMode === "equipment" & equipmentTableMode === "tower") {
                criteriaValue = criteriaValue + "'" + this.state.tableData["equipmentDetails"]["phExtRefId"][i] + "',"
            }
            else if (tableMode === "equipment" & equipmentTableMode === "ground") {
                criteriaValue = criteriaValue + "'" + this.state.groundTableData["groundDetails"]["phExtRefId"][i] + "',"
            }
            else {
                criteriaValue = criteriaValue + "'" + this.state.tableData["equipmentDetails"]["customerName"][i] + "',"
            }
        }

        criteriaValue = criteriaValue.slice(0, -1)
        criteriaValue += "]"
        criteriaAr.push({"field": tableMode === "equipment" ? "phExtRefId":"customerName", "operator": "in", "value": criteriaValue})
        return criteriaAr
    }

    setAttemptedTowerNumber(towerNumber){
        return new Promise(resolve => {
                let newState = JSON.parse(JSON.stringify(this.state));
                newState.attemptedTowerNumber = towerNumber;
                this.setState(newState);
                resolve()
            }
        )
    }

    setTowerNumber(towerNumber){
        return new Promise(resolve => {
                let newState = JSON.parse(JSON.stringify(this.state));
                newState.towerNumber = towerNumber;
                this.setState(newState);
                resolve()
            }
        )
    }

    setLoading(){
        return new Promise(resolve => {
                let newState = JSON.parse(JSON.stringify(this.state));
                newState.loading = !newState.loading;
                this.setState(newState);
                resolve()
            }
        )
    }

    async handleTowerNumberChange(value){
        //Loading will be false
        //Only force a table data update if the tower has changed
        //Otherwise, only change the view on the data
        return new Promise(async (resolve) => {
                if (value && this.state.towerNumber !== value){
                    await this.setTowerNumber(value)
                    let newState = JSON.parse(JSON.stringify(this.state));
                    
                    await this.setLoading()

                    if (this.state.towerNumber){
                        this.props.history.push("/")
                    }

                    newState.outputData = null
                    newState.tableData = null
                    newState.groundTableData = null
                    newState.isError = false
                    newState.noTowerData = false
                    newState.noGroundData = false
                    newState.selectedRows = []
                    await MakeApiCall({"endpoint":"getequipmentlist", "hash": this.state.hash, "payload":{"towerNumber": value}})
                        .then(
                            async (response) => {
                                if(response.status === 200 | response.status === 204){
                                    if (response.status === 204){
                                        newState.noTowerData = true
                                        newState.tableData = null
                                    }
                                    else{
                                        let responseJson = await response.json()
                                        newState.tableData = responseJson
                                    }
                                    return MakeApiCall({"endpoint":"getgroundspacelist", "hash": this.state.hash, "payload":{"towerNumber": value}})
                                        .then(
                                            async (response) =>{
                                                if(response.status === 200 | response.status === 204){
                                                    if (response.status === 204){
                                                        newState.noGroundData = true
                                                        newState.groundTableData = null
                                                        this.setState(newState);
                                                        resolve()
                                                    }
                                                    else{
                                                        let responseJson = await response.json()
                                                        newState.groundTableData = responseJson

                                                    }
                                                    this.setState(newState);
                                                    resolve()
                                                }
                                                else{
                                                    newState.isError = true
                                                    this.setState(newState);
                                                    resolve()
                                                }
                                            }
                                        )
                                }
                                else{
                                    newState.isError = true
                                    this.setState(newState)
                                    resolve()
                                }
                            }
                        )
                        .catch(
                            error => {
                                this.props.history.push("/error")
                            }
                        )
                }
                else if (!value){
                    let newState = JSON.parse(JSON.stringify(this.state));
                    newState.isError = false
                    newState.tableData = null;
                    newState.groundTableData = null
                    newState.noTowerData = false
                    newState.noGroundData = false
                    newState.outputData = null;
                    newState.selectedRows = [];
                    newState.towerNumber = value;
                    this.setState(newState);
                    resolve()
                }
            }
        )
    }
    async makeImageCreateApiCall(){
        if (this.state.tableMode !== "tower" & this.state.towerNumber != null & (!this.state.selectedRows || this.state.selectedRows.length === 0)){
            alert("Please select the rows you would like to view.");
        }
        else if (!this.state.selectionModeValues.imageSelectModeValue){
            alert("Please set the image selection mode.");
        }
        else if (!this.state.selectionModeValues.maskModeValue){
            alert("Please set the mask method.");
        }
        else{
            let newState = JSON.parse(JSON.stringify(this.state));
            let payload = {}
            if (this.state.tableMode === "tower"){
                payload = {
                    "towerNumber" : this.state.towerNumber,
                    "imageRequests": [
                        {
                            "contractNumbers": null, 
                            "angleCount": this.state.angleCount,
                            "selectionMethod": this.state.selectionModeValues.imageSelectModeValue.value,
                            "applyMasks": this.state.parameterValues.blnApplyMasks,
                            "showAllMasks": this.state.parameterValues.blnAllMasks,
                            "maskMethod": this.state.selectionModeValues.maskModeValue.value,
                            "blur": this.state.parameterValues.blnBlur,
                            "applyImageCaption": this.state.parameterValues.blnCaption,
                            "imageHeight":1259,
                            "imageWidth": 1678
                        }
                    ]
                }
            }
            else{
                let criteriaAr = this.buildCriteriaString();
                payload = {
                    "towerNumber" : this.state.towerNumber,
                    "imageRequests": [
                        {
                            "customEquipQuery": [criteriaAr],
                            "angleCount": this.state.angleCount,
                            "selectionMethod": this.state.selectionModeValues.imageSelectModeValue.value,
                            "applyMasks": this.state.parameterValues.blnApplyMasks,
                            "showAllMasks": this.state.parameterValues.blnAllMasks,
                            "maskMethod": this.state.selectionModeValues.maskModeValue.value,
                            "blur": this.state.parameterValues.blnBlur,
                            "applyImageCaption": this.state.parameterValues.blnCaption,
                            //occlusion, dist from cam, dist from center, angle
                            //"minPercentileAr": [.9,.8,.99,.4],
                            //"minCoverWeightAr": [.1, .9],
                            "imageHeight":1259,
                            "imageWidth": 1678
                        }
                    ]
                }
            }
            await this.setLoading()
            MakeApiCall({"endpoint": "customimagecreate", "hash": this.state.hash, "payload": payload})
                .then(
                    (response) => {
                        if(response.status === 200){
                            response.json().then(data => {
                                newState.validAudit = data['validAudit']
                                newState.flightDate = data['flightDate']
                                newState.outputData = data['imageResults'];
                                newState.carouselId = this.state.carouselId + 1
                                this.setState(newState);
                            })
                        }
                        else{
                            newState.tableData = null
                            newState.groundTableData = null
                            newState.outputData = null;
                            newState.carouselId = this.state.carouselId + 1
                            newState.selectedRows = [];
                            newState.isError = true
                            this.setState(newState);
                        }
                    }
                )
        }
    }

    getTowerNumbers(responseData){
        var optionAr = []
        for (var i in responseData["towerDetails"]["towerNumber"]) {
            optionAr.push(responseData["towerDetails"]["towerNumber"][i])
        }
        return optionAr.sort((a,b) => a-b)
    }

    async getTowerList(){
        return new Promise(async (resolve) => {
            let newState = JSON.parse(JSON.stringify(this.state));
            await this.setLoading()
            await MakeApiCall({"endpoint":"gettowerlist", "hash": this.state.hash, "payload":null})
            .then(
                response => {
                    if(response.status === 200){
                        response.json().then(
                            responseJson => {
                                newState.towerOptionAr = this.getTowerNumbers(responseJson);
                                this.setState(newState);
                                resolve()
                            }
                        )
                    }
                    else{
                        resolve()
                    }
                }
            )
            .catch(
                error => {
                    this.props.history.push("/error")
                }
            )
        }
    )
    }

    makeWarmUpCalls(){
        MakeApiCall({"endpoint":"getequipmentlist", "hash": this.state.hash, "payload": {'towerNumber': ''}})
        MakeApiCall({"endpoint":"customimagecreate", "hash": this.state.hash, "payload": {'towerNumber': '', 'imageRequests': []}})
    }

    setHash(hash){
        return new Promise(resolve => {
                let newState = JSON.parse(JSON.stringify(this.state));
                newState.hash = hash;
                this.setState(newState);
                this.props.history.push("/")
                resolve()
            }
        )
    }

    async componentDidMount(){
        let location = this.props.location;
        let params = new URLSearchParams(location.search);

        await this.setHash(location.hash.substring(1))
        await this.getTowerList()
        this.makeWarmUpCalls()
        if(params.get('towerNumber')){
            await this.setAttemptedTowerNumber(params.get('towerNumber'))
            await this.handleTowerNumberChange(params.get('towerNumber'))
            
            if(this.state.towerNumber != null & !this.state.isError){
                this.makeImageCreateApiCall()
            }
        }
    }

    render() {
        var presignedUrls = []
        if(this.state.outputData){
            for (var i in this.state.outputData){
                presignedUrls.push({"original": this.state.outputData[i]['presignedUrl'], "sizes":'(max-width: 100px)'});
                i+=1
            }
           
        }
        let margin = 2
        return (
            <Grid container spacing={2}>
                <Grid item lg={margin}></Grid>
                <Grid item container lg={12 - margin*2} spacing={2}>
                    <Grid item container>
                        <Header></Header>
                    </Grid>
                    <Grid item container spacing={2}>
                        <Grid item xs={false} sm={1}></Grid>
                        <Grid item xs={12} sm={3}>
                            <TowerSelect
                                towerOptionAr={this.state.towerOptionAr}
                                towerNumber={this.state.towerNumber}
                                attemptedTowerNumber={this.state.attemptedTowerNumber}
                                handleTowerNumberChange={(value) => this.handleTowerNumberChange(value)}
                                setAttemptedTowerNumber={(value) => this.setAttemptedTowerNumber(value)}
                            >
                            </TowerSelect>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <Grid item container>
                                <Grid item xs={12} sm={7}>
                                    <AnnotationModeSelection 
                                        annotationModeValue={this.state.annotationModeValue}
                                        handleAnnotationModeSelection={(event) => this.handleAnnotationModeSelection(event)}
                                    >
                                    </AnnotationModeSelection>
                                </Grid>
                                <Grid item xs={false} sm={1}>
                                    <Divider orientation="vertical"></Divider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ParameterSelection 
                                        disableParam={this.state.disableParamMenu}
                                        selectionModeValues={this.state.selectionModeValues}
                                        parameterValues={this.state.parameterValues}
                                        handleParameterChange={(value) => this.handleParameterChange(value)}
                                    ></ParameterSelection>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={false} sm={1}></Grid>
                    </Grid>
                    <Grid item container>
                        <ImageViewer
                            carouselId={this.state.carouselId}
                            validAudit={this.state.validAudit}
                            flightDate={this.state.flightDate}
                            outputData={this.state.outputData}
                        >
                        </ImageViewer>
                    </Grid>
                    <Grid item container>
                        <DataTableErrorHeader
                            tableMode={this.state.tableMode}
                            equipmentTableMode={this.state.equipmentTableMode}
                            noTowerData={this.state.noTowerData}
                            noGroundData={this.state.noGroundData}
                        >  
                        </DataTableErrorHeader>
                        <DataTableRadioToggle
                            isError={this.state.isError}
                            tableMode={this.state.tableMode}
                            equipmentTableMode={this.state.equipmentTableMode}
                            handleTableModeSelection={(value) => this.handleTableModeSelection(value)}
                        ></DataTableRadioToggle>
                        <DataTable 
                            isError={this.state.isError}
                            tableData={this.state.tableData}
                            groundTableData={this.state.groundTableData}
                            tableMode={this.state.tableMode}
                            equipmentTableMode={this.state.equipmentTableMode}
                            noTowerData={this.state.noTowerData}
                            noGroundData={this.state.noGroundData}
                            handleTableUpdate={(value) => this.handleTableUpdate(value)}
                            selectedRows={this.state.selectedRows}
                            handleSelectedRows={(value) => this.handleSelectedRows(value)}
                        ></DataTable>
                    </Grid>
                    <Grid item container>
                        <Grid item container justifyContent="flex-end" alignItems="center">
                            <Grid item>
                                <SubmitButton 
                                    isError={this.state.isError}
                                    tableMode={this.state.tableMode}
                                    onClick={(value)=>this.makeImageCreateApiCall()}
                                >
                                    Submit
                                </SubmitButton>
                            </Grid>
                            <Grid item xs={false} sm={1} lg={1}></Grid>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <SharePointRedirect
                            isError={this.state.isError}
                            towerNumber={this.state.attemptedTowerNumber}
                        ></SharePointRedirect>
                    </Grid>
                </Grid>
                <Grid item lg={margin}></Grid>
                <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.loading}
                    >
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <CircularProgress color="inherit" />
                            <Typography>Loading...</Typography>
                        </Stack>
                </Backdrop>
            </Grid>
        );
    }
}

export default withRouter(App);