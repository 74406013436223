import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from './app';
import Error from "./error"

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/error">
        <Error></Error>
      </Route>
      <Route path="/">
        <App></App>
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);