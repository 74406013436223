import React from 'react';
import { Carousel } from "react-responsive-carousel"
import { Grid } from '@material-ui/core'
import { Typography } from '@mui/material';

import { MapInteractionCSS } from 'react-map-interaction';

function ImageCaption(props){
    if (props.text !== ""){
        return(
            <p className="legend">{props.text}</p>
        )
    }
    else{
        return null
    }
}
function processImageData(outputData){
    let imageSet = [];
    let loading = 'eager'
    for (var i = 0; i < outputData.length; i++){
        // if(i > 3){
        //     loading = 'lazy'
        // }
        imageSet.push(
            <a key={i} target="_blank" rel="noopener noreferrer" href={outputData[i]["presignedUrl"]}>
                <MapInteractionCSS minScale={1}>
                    <img loading={loading} src={outputData[i]["presignedUrl"]} alt={outputData[i]["customerNames"].join(', ')}/>
                </MapInteractionCSS>
                {/* <ImageCaption text={outputData[i]["customerNames"].join(', ')}></ImageCaption> */}
            </a>
        )
    }
    return imageSet;
}

function TowerOutOfDate(props){
    if(props.validAudit){
        return null
    }
    else{
        return(
            <Grid item >
                <Typography 
                style={{
                    color:"#D11242", 
                    fontWeight: 600
                }}>
                    Caution! There is a newer flight in the pipeline that will be available soon. These photos may not be up to date.
                </Typography>
            </Grid>
        )
    }
}
function DisplayResults(props){
    if (props.images.length > 0){
        return(
            <Grid item container direction="column" alignItems="center" justifyContent="center">
                <TowerOutOfDate
                    validAudit={props.validAudit}
                >
                </TowerOutOfDate>
                <Grid item>
                    <Typography variant="h4">
                        Results
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle2">
                        Flown on {props.flightDate.substring(0,10)}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
    else{
        return null
    }
}

function ImageViewer(props){
    var imageSet
    if (props.outputData){
        imageSet = processImageData(props.outputData);
    }
    else {
        imageSet = [];
    }
    return (
        <Grid container>
            <DisplayResults 
                images={imageSet} 
                validAudit={props.validAudit}
                flightDate={props.flightDate}
            ></DisplayResults>
            <Grid item container>
                <Grid item xs={false} sm={1}></Grid>
                <Grid item xs={12} sm={10}>
                    <Carousel 
                        key={props.carouselId}
                        showArrows={true}
                        infiniteLoop={true}
                        showThumbs={false}
                    >
                        {imageSet}
                    </Carousel>
                </Grid>
                <Grid item xs={false} sm={1}></Grid>
            </Grid>
        </Grid>
    );
};

export default ImageViewer;