import React from "react";
import { Grid, FormLabel} from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function ParameterSelection(props){
    const selectionModeOptions = [
        {value:"optCover", label: "Optimal Cover"}, 
        {value:"minCover", label: "Minimum Cover"}, 
        {value:"individual", label: "Individual"}
    ]
    const maskMethodOptions = [
        {value:"mergeMasks", label: "Merge Masks"}, 
        {value:"blockMask", label: "Block Mask"}
    ]

    return(
        <Grid item container>
            <FormLabel component="legend">Parameters</FormLabel>
            {/* <Grid item container spacing={3}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="selectionMode"
                            options={selectionModeOptions}
                            renderInput={(params) => <TextField required {...params} label="Image Selection Mode"/>}
                            variant="outlined"
                            disabled={props.disableParam}
                            value={props.selectionModeValues.imageSelectModeValue}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            onChange={(event, newValue) => {props.handleParameterChange({"valueDict": "selectionModeValues", "id": "imageSelectModeValue", "value": newValue})}}
                        >
                        </Autocomplete>
                        <FormHelperText>Choose a selection algorithm.</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="maskMethod"
                            options={maskMethodOptions}
                            renderInput={(params) => <TextField required {...params} label="Mask Method"/>}
                            variant="outlined"
                            disabled={props.disableParam}
                            value={props.selectionModeValues.maskModeValue}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            onChange={(event, newValue) => {props.handleParameterChange({"valueDict": "selectionModeValues", "id": "maskModeValue", "value": newValue})}}
                        >
                        </Autocomplete>
                        <FormHelperText>Choose a mask behavior.</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid> */}
            <Grid item container>
                <Grid item>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                color="primary"  
                                disabled={props.disableParam}
                                checked={props.parameterValues.blnApplyMasks}
                                onClick={(event) => {props.handleParameterChange({"valueDict": "parameterValues", "id": "blnApplyMasks", "value": event.target.checked})}}
                            />
                        }
                        label="Masks" 
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                color="primary"  
                                disabled={props.disableParam}
                                checked={props.parameterValues.blnBlur}
                                onClick={(event) => {props.handleParameterChange({"valueDict": "parameterValues", "id": "blnBlur", "value": event.target.checked})}}
                            />
                        }
                        label="Blur" 
                        labelPlacement="end"
                    />
                </Grid>
                {/* <Grid item>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                color="primary" 
                                disabled={props.disableParam} 
                                checked={props.parameterValues.blnAllMasks}
                                onClick={(event) => {props.handleParameterChange({"valueDict": "parameterValues", "id": "blnAllMasks", "value": event.target.checked})}}
                            />
                        } 
                        label="Show All Masks" 
                        labelPlacement="end"
                    />
                </Grid> */}
                <Grid item>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                color="primary" 
                                disabled={props.disableParam} 
                                checked={props.parameterValues.blnCaption}
                                onClick={(event) => {props.handleParameterChange({"valueDict": "parameterValues", "id": "blnCaption", "value": event.target.checked})}}
                            />} 
                        label="Caption" 
                        labelPlacement="end"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ParameterSelection;