import React from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Grid } from "@material-ui/core";

function NoOptionsText(props){
    if(props.attemptedTowerNumber !== null){
        var noOptionsText = "Not seeing your tower? Try our sharepoint site: "
        return (
            <div>
                <p>{noOptionsText}</p>
                <a href={props.searchHref} target="_blank" rel="noopener noreferrer">Sharepoint Drone Data</a>
            </div>
        )
    }
    else{
        var noOptionsText = "Enter a tower number"
        return (
            <p>{noOptionsText}</p>
        )
    }
}

class TowerSelect extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            searchHref: null,
            towerNumberOptions: []
        }
    }

    setSearchHref(event, value, reason){
        let newState = JSON.parse(JSON.stringify(this.state));
        let hrefVal = null
        if(value !== null ){
            hrefVal = "https://atconline.sharepoint.com/sites/DroneData/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDroneData%2FShared%20Documents%2F" + value
        }

        newState.searchHref = hrefVal
        this.setState(newState);
    }

    render(){
        return(
            <Grid item>
                <Autocomplete
                    disablePortal
                    id="towerNumber"
                    options={(this.props.attemptedTowerNumber !== null) ? this.props.towerOptionAr:[]}
                    filterOptions={
                        createFilterOptions({
                            matchFrom: 'start',
                            limit: 100
                          }
                        )
                    }
                    renderInput={(params) => <TextField required {...params} label="Tower Number"/>}
                    variant="outlined"
                    defaultValue={""}
                    value={this.props.towerNumber}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, newValue) => {this.props.handleTowerNumberChange(newValue)}}
                    noOptionsText={
                        <NoOptionsText 
                            attemptedTowerNumber={this.props.attemptedTowerNumber} 
                            searchHref={this.state.searchHref}>    
                        </NoOptionsText>}
                    onInputChange={
                        async (event, value, reason) => {
                            this.setSearchHref(event, value, reason)
                            if (value === ""){
                                value = null
                            }
                            if (
                                reason === "input"
                            ){
                                await this.props.setAttemptedTowerNumber(value)
                                this.props.handleTowerNumberChange(null)
                            }
                        }
                    }
                >
                </Autocomplete>
            </Grid>
        )
    }
}

export default TowerSelect;