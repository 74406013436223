import React from 'react';
import Button from '@mui/material/Button';

function SubmitButton(props){
    if (props.isError){
        return null
    }
    else{
        return (
            <Button 
                variant="contained" 
                size="large" 
                color="primary"
                onClick={(event)=>props.onClick(event)}
            >
                Submit
            </Button>
        );
    }
};

export default SubmitButton;
