import { Grid, Typography } from '@material-ui/core';
import { Button } from '@mui/material';
import React from 'react';
import Error from './error';

function SharePointRedirect(props){
    if (!props.isError){
        return null
    }
    else{
        let searchHref = "https://atconline.sharepoint.com/sites/DroneData/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDroneData%2FShared%20Documents%2F" + props.towerNumber
        return (
            <Grid item container direction="column" alignItems="center" spacing={1}>
                <Error
                    message={"Whoops! Looks like we don't have that tower yet. Try our SharePoint site:"}
                >
                </Error>
                <Grid item>
                    <Button
                        variant="contained" 
                        size="large" 
                        color="primary"
                        href={searchHref}
                        target="_blank" rel="noopener noreferrer"
                    >
                        Sharepoint Drone Data
                    </Button>
                </Grid>
            </Grid>
        );
    }
};

export default SharePointRedirect;
