import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@material-ui/core'

function DisplayDataTableRadioToggle(props){
    if (props.tableMode === "equipment" & !props.isError){
        return (
            <FormControl>
              <RadioGroup
                row
                aria-label="tableModeSelection" 
                name="tableModeSelectionGroup" 
                value={props.equipmentTableMode} 
                onChange={(event)=>props.handleTableModeSelection(event.target.value)}
              >
                <FormControlLabel value="tower" control={<Radio />} label="Tower" />
                <FormControlLabel value="ground" control={<Radio />} label="Ground" />
              </RadioGroup>
            </FormControl>
          )
    }
    else{
        return null
    }
}
function DataTableRadioToggle(props) {
  return (
    <Grid item container>
        <Grid item xs={false} sm={1}></Grid>
        <Grid item xs={12} sm={10}>
            <DisplayDataTableRadioToggle
                isError={props.isError}
                tableMode={props.tableMode}
                equipmentTableMode={props.equipmentTableMode}
                handleTableModeSelection={(value) => props.handleTableModeSelection(value)}
            >
            </DisplayDataTableRadioToggle>
        </Grid>
        <Grid item xs={false} sm={1}></Grid>
    </Grid>
  )
}

export default DataTableRadioToggle;